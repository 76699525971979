/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: false // set to false to use static SVG
};

const greeting = {
  username: "AFA",
  title: "I'm Abdulmajeed",
  subTitle: emoji("IT graduate, Certified Full stack developer, and DevOps engineer, curious and interested in infrastructures and the cloud. 🚀"),
  resumeLink:
    "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  linkedin: "https://www.linkedin.com/in/abdulmajeed-alahmadi-008488190/",
  github: "https://github.com/MjedAl",
  gmail: "mr@afa.sa",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle:
    "I'm always curious and exited to learn more about technology, here are some of the tech stacks i have worked on",
  skills: [
    emoji(
      "⚡ Investigate and tryout different frameworks and open source projects"
    ),
    emoji("⚡ Design interactive UI for web and mobile applications"),
    emoji("⚡ Develop back ends using various frameworks"),
    emoji(
      "⚡ Integrate third party services such as Google auth / AWS into my projects"
    ),
    emoji(
      "⚡ Deploy and maintain projects on the cloud or on a privately maintained VPS"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "android dev",
      fontAwesomeClassname: "fab fa-android"
    },
    // {
    //   skillName: "postgres",
    //   fontAwesomeClassname: require("./assets/images/pg.svg")
    // },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: "fas fa-fire"
    },
    {
      skillName: "python & flask",
      fontAwesomeClassname: "fab fa-python"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: false, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Harvard University",
      logo: require("./assets/images/harvardLogo.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2017 - April 2019",
      desc: "Participated in the research of XXX and published 3 papers.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      schoolName: "Stanford University",
      logo: require("./assets/images/stanfordLogo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2013 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ...",
      descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Software Engineer",
      company: "Facebook",
      companylogo: require("./assets/images/facebookLogo.png"),
      date: "June 2018 – Present",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      descBullets: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      ]
    },
    {
      role: "Front-End Developer",
      company: "Quora",
      companylogo: require("./assets/images/quoraLogo.png"),
      date: "May 2017 – May 2018",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    },
    {
      role: "Software Engineer Intern",
      company: "Airbnb",
      companylogo: require("./assets/images/airbnbLogo.png"),
      date: "Jan 2015 – Sep 2015",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://saayahealth.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "DevOps Certification Training",
      subtitle: "",
      image: require("./assets/images/si.png"),
      footerLink: [
        {
          name: "View certificate",
          url: "https://success.simplilearn.com/265b04d8-122c-4aea-81ab-1c955fa932ef#gs.d46yfj"
        }
      ]
    },
    {
      title: "Full-Stack Developer Nanodegree",
      subtitle: "",
      image: require("./assets/images/udacity.png"),
      footerLink: [
        {
          name: "View certificate",
          url: "https://graduation.udacity.com/confirm/YYGTQDGZ"
        }
      ]
    },
    {
      title: "Flutter & Dart course",
      subtitle: "",
      image: require("./assets/images/udemy.png"),
      footerLink: [
        {
          name: "View certificate",
          url: "https://www.udemy.com/certificate/UC-3377d87c-a501-4d2e-9cc2-5c45d5b34059/"
        }
      ]
    },
    {
      title: "1st place in FCIT Programming competition",
      subtitle: "",
      image: require("./assets/images/fcit.jpg"),
      footerLink: [
        {
          name: "View certificate",
          url: "https://drive.google.com/file/d/1oc8iTumX6CLVuFy_bM6FHhDZjO4dCMbt/view?usp=sharing"
        },
        {
          name: "View work",
          url: "https://github.com/MjedAl/FCIT-Transfer-Game"
        }
      ]
    }
    // {
    //   title: "PWA Web App Developer",
    //   subtitle: "",
    //   image: require("./assets/images/pwaLogo.webp"),
    //   footerLink: [
    //     {name: "Certification", url: ""},
    //     {
    //       name: "Final Project",
    //       url: "https://pakistan-olx-1.firebaseapp.com/"
    //     }
    //   ]
    // }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Projects",
  subtitle: "Some of the projects that i have worked on",

  blogs: [
    {
      url: "https://github.com/MjedAl/simpleEMS",
      title: "simpleEMS",
      description:
        "A very simple events management system with limited functionalities, i practiced Front end & back end devolvement, how to securely register and manage users sessions, how send emails such as verification and password resets to users, how to store images in AWS S3 Bucket, using google auth to users can log in with their google accounts, API and JWT authentication, setting up a CI/CD pipeline using github actions, and many more :)",
      techStack: [
        {
          name: "Python & flask"
        },
        {
          name: "HTML, CSS, JS"
        },
        {
          name: "Bootstrap"
        },
        {
          name: "Google auth"
        },
        {
          name: "AWS"
        }
      ]
    },
    {
      url: "https://github.com/MjedAl/simpleEMS-Flutter",
      title: "simpleEMS Flutter mobile app",
      description:
        "The second part of the previous project, a mobile application built with Flutter, it was my first time trying out Flutter and i truly liked it, i practiced on creating custom widgets, managing states, handling users logging and registering via the API that i made, storing and handling the JWT, managing users input in forms, submit GET and POST requests to the API and reflect the changes in the UI",
      techStack: [
        {
          name: "Flutter"
        },
        {
          name: "APIs & JWTs"
        }
      ]
    },
    {
      url: "https://github.com/MjedAl/KSA_Students_Dashbaord",
      title: "KSA Studentds Dashbaord",
      description:
        "A small project i made to try out Power BI, it's a Dashboard for students data in Saudi Arabia public schools.",
      techStack: [
        {
          name: "Power BI"
        },
        {
          name: "Data analyzing"
        },
        {
          name: "Dashboards"
        }
      ]
    },
    {
      url: "https://github.com/MjedAl/FCIT18.link_URL",
      title: "Domain Manager",
      description:
        "A flask app that will give you and your team the ability to easily manage and track your domain, you can add subdomains, create shortcut's and track all clicks. there's also an interactive and user friendly reporting dashboard built with Metabase",
      techStack: [
        {
          name: "Python & Flask"
        },
        {
          name: "Data management"
        },
        {
          name: "Metabase"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+92-3243454077",
  email_address: "saadpasta70@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
